// Routes.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminLogin from './AdminLogin'; 
import MainCategory from "./MainCategory";
import User from './User';
import AddMembers from './AddMembers';
import ForgotPassword from './forgotPassword';
import Dashboard from './Dashboard';
import Profile from './Profile';
import Members from './Members';
export default function AppRoutes() {
  return (
    <Router>
      <Routes>
      <Route index element={<AdminLogin />} />
      <Route path="/AdminLogin" element={<AdminLogin />} />
        <Route path="/main-category" element={<MainCategory />} />
        <Route path="/user" element={<User />} />
        <Route path="/viewmembers" element={<MainCategory />} />
        <Route path="/addmembers" element={<AddMembers />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/Members" element={<Members />} />
      </Routes>
    </Router>
  );
}


