import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate, NavLink } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import './style.scss';
import './ekka.scss';
import './materialdesignicons.scss';
import { FaUserPlus } from 'react-icons/fa';
import sidelogo from './img/logos/logo-text.png'

const menuItems = [
  // { text: "Dashboard", icon: "mdi mdi-view-dashboard-outline", link: "index" },
  // Add more menu items as needed
];
const SideBar = () => {

  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState({
    vendors: false,
    user: false,
    categories: false,
    products: false,
    orders: false,
    authentication: false,
    icons: false,
    otherpages: false,
    dashboard: false,
    banner: false,
    offer: false,
    cinfo: false,
    cdetails: false,
    cminfo: false,
    coupon: false,
    pincode: false,
    deliverycharges: false,
    showpincode: false,
  });
  const [userData, setUserData] = useState({});
  useEffect(() => {
    fetchuserData();
  }, []);
  const fetchuserData = async () => {
    try {
      const response = await axios.post("https://api.yositechmedia.com/api/v1/login/getuserdetails", {}, {

        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 401 || response.status === 404) {
        navigate('/AdminLogin');
        return;
      }
      setUserData(response.data);
    } catch {
      console.log("Error in fetching data")
    }
  };
  return (
    <div>
      <div className="ec-left-sidebar ec-bg-sidebar d-none d-md-flex" style={{position:"fixed" , width:"170px"}}>
        <div id="sidebar" className="sidebar ec-sidebar-footer">

          {/* Brand Section */}
          <div className="ec-brand">
            <a href="/" title="Yadhav">
            <img src={sidelogo} />
              {/* <h4>
                <span className="ec-brand-name text-truncate">YADHAV</span>
              </h4> */}
            </a>
          </div>
          <div className="ec-navigation" data-simplebar>
            <ul className="nav sidebar-inner" id="sidebar-menu">
              {menuItems.map((item, index) => (
                <li key={index}>
                  <NavLink
                    to={item.link}
                    className={({ isActive }) =>
                      isActive ? "sidenav-item-link ant-menu-item-selected" : "sidenav-item-link"
                    }
                  >
                    <i className={item.icon}></i>
                    <span className="nav-text">{item.text}</span>
                  </NavLink>
                </li>
              ))}
              <li className={`has-sub ${isSubMenuOpen.dashboard ? 'expand' : ''}`}>
                <NavLink to="/dashboard" className={({ isActive }) => isActive ? "ant-menu-item-selected" : ""} end>
                  <i className="mdi mdi-view-dashboard"></i>
                  <span className="nav-text">Dashboard</span>
                </NavLink>
              </li>

              {/* User Menu Item */}
              {(userData.role === 'SUPERADMIN' || userData.role === 'ADMIN') && (
                <li className={`has-sub ${isSubMenuOpen.categories ? 'expand' : ''}`}>
                  <NavLink to="/user" className={({ isActive }) => isActive ? "ant-menu-item-selected" : ""} end>
                    <i className="mdi mdi-account-group"></i>
                    <span className="nav-text">View Member</span>
                  </NavLink>
                </li>
              )}
              {(userData.role === 'SUPERADMIN' || userData.role === 'ADMIN') && (
              <li className={`has-sub ${isSubMenuOpen.offer ? 'expand' : ''}`}>
                <NavLink to="/members" className={({ isActive }) => isActive ? "ant-menu-item-selected" : ""} end>
                  <i className="mdi mdi-dns-outline"></i>
                  <span className="nav-text">Add Members</span>
                </NavLink>
              </li>
              )}
              {/* View Members Menu Item */}
              <li className={`has-sub ${isSubMenuOpen.banner ? 'expand' : ''}`}>
                <NavLink to="/viewmembers" className={({ isActive }) => isActive ? "ant-menu-item-selected" : ""} end>
                  <i className="mdi mdi-account"></i>
                  <span className="nav-text">View Voters</span>
                </NavLink>
              </li>

              {/* Add Members Menu Item */}
              <li className={`has-sub ${isSubMenuOpen.offer ? 'expand' : ''}`}>
                <NavLink to="/addmembers" className={({ isActive }) => isActive ? "ant-menu-item-selected" : ""} end>
                  <i className="mdi mdi-dns-outline"></i>
                  <span className="nav-text">Add Voters</span>
                </NavLink>
              </li>

            </ul>
          </div>
        </div>
      </div>
      <div className="footer d-flex d-md-none">
        <div className=' container'>

          <nav className='footer-bar1'>
            <li className="bar">
              <NavLink
                to={'/dashboard'}
                className={({ isActive }) => isActive ? 'current active' : 'current'}
              >
                <div className="bar">
                  <div className="navIcon"><i className="mdi mdi-view-dashboard"></i></div>
                </div>
                <div className='fontsize'>Dashboard</div>
              </NavLink>
            </li>
            {(userData.role === 'SUPERADMIN' || userData.role === 'ADMIN') && (
            <li className="bar">
              <NavLink
                to={'/user'}
                className={({ isActive }) => isActive ? 'current active' : 'current'}
              >
                <div className="bar">
                  <div className="navIcon"><i className="mdi mdi-account-group"></i></div>
                </div>
                <div className='fontsize'>Member</div>
              </NavLink>
            </li>
            )}
            {/* {(userData.role === 'SUPERADMIN' || userData.role === 'ADMIN') && (
            <li className="bar">
              <NavLink
                to={'/members'}
                className={({ isActive }) => isActive ? 'current active' : 'current'}
              >
                <div className="bar">
                  <div className="navIcon"><FaUserPlus /></div>
                </div>
                <div className='fontsize'>Add</div>
              </NavLink>
            </li>
            )} */}
            <li className="bar">
              <NavLink
                to={'/main-category'}
                className={({ isActive }) => isActive ? 'current active' : 'current'}
              >
                <div className="bar">
                  <div className="navIcon"><i className="mdi mdi-account"></i></div>
                </div>
                <div className='fontsize'>Voters</div>
              </NavLink>
            </li>
            {/* <li className="bar">
              <NavLink
                to={'/addmembers'}
                className={({ isActive }) => isActive ? 'current active' : 'current'}
              >
                <div className="bar">
                  <div className="navIcon"><i className="mdi mdi-dns-outline"></i></div>
                </div>
                <div className='fontsize'>AddVoters</div>
              </NavLink>
            </li> */}

          </nav>

        </div>
      </div>

    </div>
  );
}
export default SideBar;