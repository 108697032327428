// App.js

import React from 'react';
import './App.css';
import AppRoutes from './Routes';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <div className="body">
        <div className="content-body">
          <AppRoutes />
        </div>

      </div>
    </div>
  );
}

export default App;
