import React, { useState, useEffect } from 'react';
import './style.scss';
import './ekka.scss';
import SideBar from './sidebar';
import Header from './Header';
import { NavLink, useNavigate } from 'react-router-dom';
import { Modal, Button, Spin, Form, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from "axios";
import { Select } from "antd";
import EditSubCategoryModal from './EditProduct';
import { DatePicker } from "antd";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { confirm } = Modal;

const DeleteCategoryModal = ({ visible, onCancel, onConfirm }) => {
	return (
		<Modal
			open={visible}
			title="Confirm Deletion"
			onCancel={onCancel}
			footer={[
				<Button key="cancel" onClick={onCancel}>
					Cancel
				</Button>,
				<Button key="delete" type="primary" onClick={onConfirm}>
					Delete
				</Button>,
			]}
		>
			<p>Are you sure you want to delete this pincode?</p>
		</Modal>
	);
};
const ProductAdd = () => {
	const token = localStorage.getItem('token');
	const [data, setData] = useState([]);
	const [download, setDownload] = useState([]);
	const [loading, setLoading] = useState(true);
	const [fileid, setFileid] = useState([]);
	const [fileList, setFileList] = useState([]);
	const navigate = useNavigate();
	const [firstname, setFirstname] = useState('');
	const [mobile, setMobile] = useState('');
	const [email, setEmail] = useState('');
	const [fathername, setFathername] = useState("");
	const [dob, setDob] = useState(null);
	const [uid, setUid] = useState('');
	const [address, setAddress] = useState("");
	const [area, setArea] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [pincode, setPincode] = useState("");
	const [mparea, setMparea] = useState("");
	const [mlaarea, setMlaarea] = useState("");
	const [mpAreaList, setMpAreaList] = useState([]);
	const [mlaAreaList, setMlaAreaList] = useState([]);
	const [mlaAreaLists, setMlaAreaLists] = useState([]);
	const [selectedMp, setSelectedMp] = useState(null);
	const [selectedMla, setSelectedMla] = useState(null);
	const [selectedOccupation, setSelectedOccupation] = useState(null);
	const [gender, setGender] = useState("");
	const [occupation, setOccupation] = useState("");
	const [married, setMarried] = useState("");
	const [spousename, setSpousename] = useState("");
	const [showOccupationInput, setShowOccupationInput] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [userData, setUserData] = useState({});
	const [errors, setErrors] = useState({});
	const [showEditModal, setShowEditModal] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [viewOnly, setViewOnly] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [filteredData, setFilteredData] = useState([]);

	const handleEditCategory = (category) => {
		setSelectedCategory(category);
		setShowEditModal(true);
		setViewOnly(false);
	};
	const handleViewCategory = (category) => {
		setSelectedCategory(category);
		setShowEditModal(true);
		setViewOnly(true);
	};
	const handleCloseEditModal = () => {
		setSelectedCategory(null);
		setShowEditModal(false);
		setViewOnly(false);
	};
	const handleDeleteCategoryConfirmed = async () => {
		try {

			setLoading(true);
			const response = await axios.post(
				`https://api.yositechmedia.com/api/v1/user/delete`,
				{
					id: selectedCategory,
				},
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),
						'Content-Type': 'application/json',
					},
				}
			);

			if (response.status === 200) {
				window.location.reload();
			} else {
				console.error('Failed to delete category');
			}
		} catch (error) {
			console.error('Error deleting category:', error);
		} finally {
			setLoading(false);
			setShowDeleteModal(false);
		}
	};
	const handleSaveEdit = async (editedData) => {
		try {
			const confirmResult = await new Promise((resolve) => {
				confirm({
					title: 'Do you want to save the changes?',
					onOk() {
						resolve(true);
					},
					onCancel() {
						resolve(false);
					},
				});
			});

			if (!confirmResult) {
				return;
			}
			setLoading(true);
			editedData.id = selectedCategory.id;
			editedData.firstname = editedData.firstname;
			editedData.dob = editedData.dob;
			editedData.address = editedData.address;
			editedData.aadharcardnumber = editedData.aadharcardnumber;
			editedData.gender = editedData.gender;
			editedData.role = editedData.role;
			const response = await axios.post(
				`https://api.yositechmedia.com/api/v1/user/edit`,
				editedData,
				{
					headers: {
						Authorization: 'Bearer ' + token,
						'Content-Type': 'application/json',
					},
				}
			);
			if (response.status === 401 || response.status === 404) {
				navigate('/login');
				return;
			}
			toast(response.data.description, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});

			if (response.status === 200) {
				console.log('Subcategory updated successfully');
				window.location.reload();
			} else {
				console.error('PUT request failed');
			}
		} catch (error) {
			console.error('Error updating data:', error);
		} finally {
			setLoading(false);
			handleCloseEditModal();
		}
	};

	useEffect(() => {
		if (!token) {
			navigate('/AdminLogin');
			return;
		}
		fetchData();
		fetchuserData();
	}, []);

	const fetchuserData = async () => {
		try {
			const response = await axios.post("https://api.yositechmedia.com/api/v1/login/getuserdetails", {}, {

				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-Type': 'application/json',
				},
			});

			if (response.status === 401 || response.status === 404) {
				navigate('/AdminLogin');
				return;
			}
			setUserData(response.data);
		} catch {
			console.log("Error in fetching data")
		}
	};
	const first10Pincodes = data.slice(0, 10);
	const fetchData = async () => {
		try {
			const response = await axios.post("https://api.yositechmedia.com/api/v1/user/get", {}, {

				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-Type': 'application/json',
				},
			});
			if (response.status === 401 || response.status === 404) {
				navigate('/AdminLogin');
				return;
			}
			setData(response.data);
			setFilteredData(response.data);
			setLoading(false);
		} catch (error) {
			console.error("Error fetching data:", error);
			setLoading(false);
		}
	};
	useEffect(() => {
		const filteredResults = data.filter(
			(item) =>
				(selectedCategory ? item.companycategory === selectedCategory : true) &&
				((item?.lastname ?? "")
					.toLowerCase()
					.includes(searchQuery.toLowerCase()) ||
					(item?.firstname ?? "")
						.toLowerCase()
						.includes(searchQuery.toLowerCase()) ||
					(item?.email ?? "").toLowerCase().includes(searchQuery.toLowerCase()) ||
					(item?.mobile ?? "").toLowerCase().includes(searchQuery.toLowerCase()) ||
					(item?.aadharnumber ?? "")
						.toLowerCase()
						.includes(searchQuery.toLowerCase()) ||
					(item?.dob ?? "").toLowerCase().includes(searchQuery.toLowerCase()) ||
					(item?.gender ?? "")
						.toLowerCase()
						.includes(searchQuery.toLowerCase()) ||
					(item?.address ?? "")
						.toLowerCase()
						.includes(searchQuery.toLowerCase()))
		);
		setFilteredData(filteredResults);
	}, [searchQuery, data, selectedCategory]);
	const validateForm = () => {
		let valid = true;
		let errors = {};
		const mobileRegex = /^\d{10}$/;
		if (!mobileRegex.test(mobile)) {
			errors.mobile = "Mobile number must be exactly 10 digits.";
			valid = false;
		}
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(email)) {
			errors.email = "Please enter a valid email address.";
			valid = false;
		}
		const aadhaarRegex = /^\d{12}$/;
		if (!aadhaarRegex.test(uid)) {
			errors.uid = "Aadhaar card number must be exactly 12 digits.";
			valid = false;
		}
		setErrors(errors);
		return valid;
	};
	const onFinish = async (e) => {
		e.preventDefault();
		if (!validateForm()) {
			return;
		}
		const token = localStorage.getItem('token');
		const fileIdsArray = Array.isArray(fileid) ? fileid : [fileid];
		try {
			setLoading(true);
			const response = await fetch("https://api.yositechmedia.com/api/v1/login/register", {
				method: "POST",
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ firstname, mobile, email, fathername, dob, uid, address, area, city, state, pincode, mparea, mlaarea, gender, occupation, married, spousename, fileIds: fileIdsArray, }),
			});
			const data = await response.json();
			if (response.status === 401 || response.status === 404) {
				toast.error("Unauthorized or Not Found. Redirecting to login...", {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				setLoading(false);
				navigate('/AdminLogin');
				return;
			}
			if (response.ok) {
				toast.success(data.message || "User registered successfully!", {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				setTimeout(() => window.location.reload(), 1000);
			} else if (data.message.includes("Already Registered")) {
				if (data.message.includes("Email Already Registered")) {
					toast.error("Email is already registered. Please use a different email.", {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
					});
				} else if (data.message.includes("Mobile Already Registered")) {
					toast.error("Mobile is already registered. Please use a different mobile number.", {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
					});
				}
				setLoading(false);
			} else {
				toast.error("Registration failed. Please try again later.", {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				setLoading(false);
			}
		} catch (error) {
			console.error("Error posting data:", error);
			toast.error("An error occurred during registration.", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			setLoading(false);
		}
	};
	const handleFileUploadforMultipleImages = async (options) => {
		const { onSuccess, onError, file, onProgress } = options;
		const formData = new FormData();
		formData.append("file", file);

		try {
			const response = await axios.post(
				"https://api.yositechmedia.com/api/v1/member/uploadgallery?file",
				formData,
				{
					onUploadProgress: (progressEvent) => {
						const percentCompleted = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total
						);
						onProgress({ percent: percentCompleted });
					},
					headers: {
						Authorization: 'Bearer ' + token,
						// 'Content-Type': 'application/json',
						'Content-Type': 'multipart/form-data',
						'Access-Control-Allow-Origin': '*',
					},
				}
			);
			if (response.status === 401 || response.status === 404 || !token) {
				navigate('/AdminLogin');
				return;
			}
			const organizerId = response.data.fileid;
			console.log(organizerId)
			setFileid((prevUploadFiles) => [...prevUploadFiles, response.data.fileid]);
			setFileList(prevFileList => [
				...prevFileList,
				{ fid: response.data.fileid, name: file.name, status: 'done', url: response.data.uploadedUrl }
			]);
			onSuccess("File uploaded");
		} catch (error) {
			onError("File upload failed");
		}
	};
	const handleDeleteCategory = (categoryId) => {
		setSelectedCategory(categoryId);
		setShowDeleteModal(true);
	};
	useEffect(() => {
		const fetchMpArea = async () => {
			try {
				const response = await axios.get(
					"https://api.yositechmedia.com/api/v1/mp-areas/getall",
					{
						headers: {
							Authorization: "Bearer " + token,
							"Content-Type": "application/json",
						},
					}
				);
				if (response.status === 401 || response.status === 404) {
					navigate("/AdminLogin");
					return;
				}
				setMpAreaList(response.data);
			} catch (error) {
				console.error("Error fetching MP Areas:", error);
			}
		};
		const fetchMlaAreas = async () => {
			try {
				const response = await axios.get(
					"https://api.yositechmedia.com/api/v1/mla-areas/getall",
					{
						headers: {
							Authorization: "Bearer " + token,
							"Content-Type": "application/json",
						},
					}
				);
				if (response.status === 401 || response.status === 404) {
					navigate("/AdminLogin");
					return;
				}
				setMlaAreaLists(response.data);
			} catch (error) {
				console.error("Error fetching MP Areas:", error);
			}
		};
		if (token) {
			fetchMpArea();
			fetchMlaAreas();
		} else {
			navigate("/AdminLogin");
		}
	}, [token, navigate]);

	const fetchMlaArea = async (mpAreaId) => {
		try {
			const response = await axios.get(
				`https://api.yositechmedia.com/api/mp-areas/${mpAreaId}`,
				{
					headers: {
						Authorization: "Bearer " + token,
						"Content-Type": "application/json",
					},
				}
			);

			if (response.status === 401 || response.status === 404) {
				navigate("/AdminLogin");
				return;
			}

			const mlaAreas = response.data.areaModels || []; // Ensure it is always an array
			setMlaAreaList(mlaAreas);
		} catch (error) {
			console.error("Error fetching MLA Areas:", error);
			setMlaAreaList([]); // Handle error by resetting the list
		}
	};

	const handleMpAreaChange = (e) => {
		const selectedMpAreaId = e.target.value;
		setMparea(selectedMpAreaId);
		if (selectedMpAreaId) {
			fetchMlaArea(selectedMpAreaId);
		} else {
			setMlaAreaList([]);
		}
	};
	const handleMpChange = async (e) => {
		setSelectedMp(e.target.value);
	};
	const handleMlaChange = async (e) => {
		setSelectedMla(e.target.value);
	};
	const handleOccupationChange = async (e) => {
		setSelectedOccupation(e.target.value);
	};
	useEffect(() => {
		applyFilters(selectedMp, selectedMla, selectedOccupation);
	}, [selectedMp, selectedMla, selectedOccupation]);
	const applyFilters = async (mparea, mlaarea, occupation) => {
		try {
			const filterData = {
				mparea: mparea || null,
				mlaarea: mlaarea || null,
				occupation: occupation || null,
			};
			const response = await axios.post("https://api.yositechmedia.com/api/v1/user/filterAll", filterData, {
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-Type': 'application/json',
				},
			});
			setFilteredData(response.data);
		} catch (error) {
		}
	};
	const processedOccupations = Array.from(
		new Set(
			data
				.filter((job) => job.occupation)
				.map((job) => job.occupation.trim().toLowerCase())
		)
	).map((occupation) =>
		data.find((job) => job.occupation && job.occupation.trim().toLowerCase() === occupation)
	);
	const handleDownload = async () => {
		try {
			const params = new URLSearchParams();
			if (selectedMp) {
				params.append('mparea', selectedMp);
			}
			if (selectedMla) {
				params.append('mlaarea', selectedMla);
			}
			if (selectedOccupation) {
				params.append('occupation', selectedOccupation);
			}
			const token = localStorage.getItem("token");
			const response = await axios.get(
				`https://api.yositechmedia.com/api/v1/report/export-user?${params.toString()}`,
				{
					headers: {
						'Authorization': 'Bearer ' + token,
						'Content-Type': 'application/json',
					},
					responseType: 'blob',
				}
			);
			downloadCSV(response.data, 'voter_report.csv');
		} catch (error) {
			console.error("Error in downloading the file:", error);
		}
	};
	const downloadCSV = (blobData, fileName) => {
		const url = window.URL.createObjectURL(new Blob([blobData]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', fileName);
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	};
	return (
		<div className="wrapper">

			{/* <!-- LEFT MAIN SIDEBAR --> */}
			<SideBar />

			{/* <!-- Header --> */}
			<Header />

			{/* <!-- CONTENT WRAPPER --> */}
			<div className="ec-content-wrapper">
				<div className="content" style={{ marginTop: "40px" }}>

					<div className="col-lg-12  justify-content-between ">
						<div className='d-flex justify-content-between'>
						<h4 className='text-truncate'>MEMBERS</h4>
							<button onClick={handleDownload} className="filter-dropdown">Download</button>
							<NavLink to={'/members'}><button className="add-item">Add</button></NavLink>

						</div>


					</div>
					<div className='row  scrollable-container'>
						<div className='col-lg-12'>


							<select
								className="filter-dropdown"
								onChange={handleMpChange}
							>
								<option value="">All MP Area</option>
								{mpAreaList.map((area) => (
									<option key={area.mpid} value={area.area}>
										{area.area}
									</option>
								))}
							</select>
							<select
								className="filter-dropdown"
								onChange={handleMlaChange}
							>
								<option value="">All MLA Area</option>
								{mlaAreaLists.map((area) => (
									<option key={area.mlaid} value={area.area}>
										{area.area}
									</option>
								))}
							</select>
							<select
								className="filter-dropdown"
								onChange={handleOccupationChange}
							>
								<option value="">select Occupation</option>
								{processedOccupations.map((job) => (
									<option key={job.id} value={job.occupation}>
										{job.occupation}
									</option>
								))}
							</select>
							<input
								className="input-box"
								type="text"
								placeholder="Search..."
								value={searchQuery}
								onChange={(e) => setSearchQuery(e.target.value)}
							/>

						</div>
					</div>
					{loading ? (
						<div style={{ textAlign: 'center', paddingTop: '50px' }}>
							<Spin size="large" />
						</div>
					) : (
						<div className="row">

							<div className="col-xl-12 col-lg-12 col-sm-12">
								<div className="ec-cat-list card card-default">
									<div className="card-body">
										<div className="table-responsive">
											<table id="responsive-data-table" className="table">
												<thead>
													<tr>
														<th>Name</th>
														<th class="hide-mobile">Mobile Number</th>
														<th class="hide-mobile">Email</th>
														<th class="hide-mobile">DOB</th>
														<th class="hide-mobile">Gender</th>
														<th>Role</th>
														{userData.role == 'SUPERADMIN' && <th>Action</th>}
													</tr>
												</thead>
												<tbody>
													{Array.isArray(data) &&
														filteredData.map((category) => (
															<tr key={category.id}>
																<td>{category.firstname}</td>
																<td class="hide-mobile">{category.mobile}</td>
																<td class="hide-mobile">{category.email}</td>
																<td class="hide-mobile">{category.dob}</td>
																<td class="hide-mobile">{category.gender}</td>
																<td>{category.role}</td>
																{/* <td>{category.address}</td> */}
																{userData.role == 'SUPERADMIN' && <td>
																	<div className="btn-group">
																		<a
																			href="#"
																			className="btn btn-outline-primary"
																			onClick={(e) => {
																				e.preventDefault();
																				handleViewCategory(category);
																			}}
																		>
																			View
																		</a>
																		<a
																			href="#"
																			className="btn btn-outline-success"
																			onClick={(e) => {
																				e.preventDefault();
																				handleEditCategory(category);
																			}}
																		>
																			Edit
																		</a>
																		<a
																			href="#"
																			className="btn btn-outline-danger"
																			onClick={(e) => {
																				e.preventDefault();
																				handleDeleteCategory(category.id);
																			}}
																		>
																			Delete
																		</a>
																	</div>                </td>}
															</tr>
														))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>

			{selectedCategory && (
				<EditSubCategoryModal
					show={showEditModal}
					onHide={handleCloseEditModal}
					onSave={handleSaveEdit}
					category={selectedCategory}
					viewOnly={viewOnly}
				/>
			)}
			{selectedCategory && (
				<DeleteCategoryModal
					visible={showDeleteModal}
					onCancel={() => setShowDeleteModal(false)}
					onConfirm={handleDeleteCategoryConfirmed}
				/>
			)}
			<ToastContainer />
		</div>

	);
}
export default ProductAdd;